import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from './../../../environments/environment';
import { Sessionempleado } from './../interfaces/sessionempleado';
import { Observable, BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SessionempleadoService {

  public arrayObservable = new BehaviorSubject<Sessionempleado[]>(null);
  public array$ = this.arrayObservable.asObservable();

  constructor(
    private http: HttpClient
  ) { }

  // public getAll(): Observable<Sessionempleado[]> {/**GetAll */
  //   return this.http.get<Sessionempleado[]>(environment.urlApi + '/SessionEmpleado');
  // }

  // public post(fkempleado: number, correoelectronico: string): Observable<object> {/**Create */
  //   return this.http.post(environment.urlApi + '/SessionEmpleado', { fkempleado: fkempleado, correoelectronico: correoelectronico });
  // }

  // public get(id: number): Observable<Sessionempleado> {/**GetOne */
  //   return this.http.get<Sessionempleado>(environment.urlApi + '/SessionEmpleado/' + id);
  // }

  // public put(object: Sessionempleado): Observable<object> {/**Update */
  //   return this.http.put(environment.urlApi + '/SessionEmpleado/' + object.id, object);
  // }

  public delete(object: Sessionempleado): Observable<object> {/**Delete */
    try {
      return this.http.delete(environment.urlApi + '/SessionEmpleado/' + object.id);
    } catch (error) {
      return null;
    }
  }
}