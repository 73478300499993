import { DefaultLayoutComponent } from './containers';
import { GuardianGuard } from './guardian.guard';
import { LoginComponent } from './views/login/login.component';
import { NgModule } from '@angular/core';
import { P404Component } from './views/error/404.component';
import { Routes, RouterModule } from '@angular/router';

export const routes: Routes = [
  {
    path: '',
    redirectTo: 'login',
    pathMatch: 'full',
  },
  {
    path: 'login',
    component: LoginComponent,
    data: {
      title: 'Login'
    }
  },
  {
    path: 'login/:enlace',
    component: LoginComponent,
    data: {
      title: 'Login'
    }
  },
  {
    path: '',
    component: DefaultLayoutComponent,
    children: [
      {
        path: 'dashboard',
        canActivate: [GuardianGuard],
        loadChildren: () => import('./views/dashboard/dashboard.module').then(m => m.DashboardModule)
      },
      {
        path: 'accion',
        canActivate: [GuardianGuard],
        loadChildren: () => import('./modulo/accion/accion.module').then(m => m.AccionModule)
      },
      {
        path: 'admin',
        canActivate: [GuardianGuard],
        loadChildren: () => import('./modulo/admin/admin.module').then(m => m.AdminModule)
      },
      {
        path: 'cita',
        canActivate: [GuardianGuard],
        loadChildren: () => import('./modulo/cita/cita.module').then(m => m.CitaModule)
      },
      {
        path: 'empleado',
        canActivate: [GuardianGuard],
        loadChildren: () => import('./modulo/empleado/empleado.module').then(m => m.EmpleadoModule)
      },
      {
        path: 'llamada',
        canActivate: [GuardianGuard],
        loadChildren: () => import('./modulo/llamada/llamada.module').then(m => m.LlamadaModule)
      },
      {
        path: 'maestro',
        canActivate: [GuardianGuard],
        loadChildren: () => import('./modulo/maestro/maestro.module').then(m => m.MaestroModule)
      },
      {
        path: 'proceso',
        canActivate: [GuardianGuard],
        loadChildren: () => import('./modulo/proceso/proceso.module').then(m => m.ProcesoModule)
      },
      {
        // path: 'programaciondesocupacion',
        path: 'programacion',
        canActivate: [GuardianGuard],
        loadChildren: () => import('./modulo/programaciondesocupacion/programaciondesocupacion.module').then(m => m.ProgramaciondesocupacionModule)
      },
      {
        path: 'reporte',
        canActivate: [GuardianGuard],
        loadChildren: () => import('./modulo/reporte/reporte.module').then(m => m.ReporteModule)
      },
      {
        path: 'seguimientopeticion',
        canActivate: [GuardianGuard],
        loadChildren: () => import('./modulo/seguimientopeticion/seguimientopeticion.module').then(m => m.SeguimientopeticionModule)
      },
      {
        path: 'tercero',
        canActivate: [GuardianGuard],
        loadChildren: () => import('./modulo/tercero/tercero.module').then(m => m.TerceroModule)
      },
      {
        path: 'seguimiento',
        canActivate: [GuardianGuard],
        loadChildren: () => import('./modulo/seguimiento/seguimiento.module').then(m => m.SeguimientoModule)
      },
      {
        path: 'tablero',
        canActivate: [GuardianGuard],
        loadChildren: () => import('./modulo/tablero/tablero.module').then(m => m.TableroModule)
      },

    ]
  },
  { path: '**', component: P404Component }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
