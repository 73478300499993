<app-header style="padding-left: 50px;" [navbarBrandRouterLink]="['/dashboard']" [fixed]="true"
  [navbarBrandFull]="{src: urlImagen + '/eficiencianet.png', width: 199, height: 26, alt: 'Logo'}"
  [navbarBrandMinimized]="{src: urlImagen + '/eficiencianet.png', width: 32, height: 32, alt: 'Logo'}"
  [sidebarToggler]="''" [asideMenuToggler]="''">
  <ul class="nav navbar-nav ml-auto">
    <li class="nav-item dropdown" dropdown placement="bottom right">
      <div class="dropdown-menu dropdown-menu-right" *dropdownMenu aria-labelledby="simple-dropdown">
        <a class="dropdown-item" *ngFor="let detail of array" [routerLink]="[detail.enlace]">
          [{{detail.fecha}}] {{detail.texto}}
        </a>
      </div>
    </li>
  </ul>
</app-header>
<div class="app-body">
  <app-sidebar *ngIf="!perfil.includes('Tercero')" #appSidebar [fixed]="true" [display]="'lg'"
    [minimized]="sidebarMinimized" (minimizedChange)="toggleMinimize($event)">
    <app-sidebar-nav [navItems]="navItems" [perfectScrollbar] [disabled]="appSidebar.minimized"></app-sidebar-nav>
    <app-sidebar-minimizer></app-sidebar-minimizer>
  </app-sidebar>
  <main class="main" style="background-color: #ffffff;">
    <cui-breadcrumb>
      <div style="margin-right: auto;" class="col-12 col-md-6 mb-2">
        <span style="font-size: 125%; font-weight: unset; color: #000000 !important;">
          Bienvenido <b style="color: #b6c733 !important;"> {{nombre}} </b>
          <br><small style="font-size: 125%; font-weight: unset; color: #000000 !important;"
            *ngIf="perfil.includes('Empleado')">{{departamento}}</small>
        </span>
      </div>
      <div class="col-12 col-md-6 mb-2" style="display: flex;">
        <div class="btn-group mr-2" role="group" style="margin-left: auto;">
          <button type="button" class="btn btn-sm btn-danger p-2" (click)="logout()">
            <i class="fa fa-sign-out"></i> Cerrar<br>sesión
          </button>
          <button class="btn btn-sm btn-info p-2" (click)="cargarLlamadas()">
            <span *ngIf="!cargando"><i class="fa fa-phone"></i> Cargar<br>llamadas</span>
            <span *ngIf="cargando"><i class="fa fa-refresh"></i></span>
          </button>
        </div>
      </div>
      <div class="col-md-12 d-block d-sm-block d-md-none"
        *ngIf="perfil.includes('Admin') || perfil.includes('Empleado')">
        <button type="button" class="btn btn-block btn-primary" routerLink="/dashboard">Tablero de servicios</button>
      </div>
    </cui-breadcrumb>
    <div class="container-fluid">
      <router-outlet></router-outlet>
    </div>
  </main>
</div>
<app-footer>
  <style>
    #btnmov {
      transform: scale(1);
      animation: mymove 3s infinite;
    }

    @keyframes mymove {
      0% {
        transform: scale(1);
      }

      50% {
        transform: scale(1.15);
      }

      100% {
        transform: scale(1);
      }
    }
  </style>
  <a *ngIf="this.perfil === 'Tercero'" href="https://api.whatsapp.com/send?phone=573106965130&text=Hola!%20necesito%20informaci%C3%B3n.%20Mi%20nombre%20es%3A"
    target="_blank">
    <img id="btnmov" class="el-image" alt="" src="https://test.gespronapolo.com/wppatencion-2.png" width="auto"
      height="55px" style="
        position: fixed;
        bottom: 75px;
        right: 25px;
        z-index: 9999;">
  </a>
</app-footer>