import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from './../../../environments/environment';
import { Sessiontercero } from './../interfaces/sessiontercero';
import { Observable, BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SessionterceroService {

  public arrayObservable = new BehaviorSubject<Sessiontercero[]>(null);
  public array$ = this.arrayObservable.asObservable();

  constructor(
    private http: HttpClient
  ) { }

  // public getAll(): Observable<Sessiontercero[]> {/**GetAll */
  //   return this.http.get<Sessiontercero[]>(environment.urlApi + '/SessionTercero');
  // }

  // public post(object: Sessiontercero): Observable<object> {/**Create */
  //   return this.http.post(environment.urlApi + '/SessionTercero', object);
  // }

  // public get(id: number): Observable<Sessiontercero> {/**GetOne */
  //   return this.http.get<Sessiontercero>(environment.urlApi + '/SessionTercero/' + id);
  // }

  // public put(object: Sessiontercero): Observable<object> {/**Update */
  //   return this.http.put(environment.urlApi + '/SessionTercero/' + object.id, object);
  // }

  public delete(object: Sessiontercero): Observable<object> {/**Delete */
    try {
      return this.http.delete(environment.urlApi + '/SessionTercero/' + object.id);
    } catch (error) {
      return null;
    }
  }
}