import { INavData } from '@coreui/angular';

export const navItemsAdmin: INavData[] = [
  {
    name: 'Tablero de Servicios',
    url: '/dashboard',
    icon: 'icon-speedometer',
  },
  {
    title: true,
    name: 'Módulos'
  },
  {
    name: 'Usuarios',
    url: '/usuarios',
    icon: 'icon-menu',
    children: [
      {
        name: 'Admin',
        url: '/admin',
        icon: 'icon-user'
      },
      {
        name: 'Empleado / Abogado',
        url: '/empleado',
        icon: 'icon-user'
      },
      {
        name: 'Cliente',
        url: '/tercero',
        icon: 'icon-user'
      },
    ]
  },
  {
    name: 'General',
    url: '/general',
    icon: 'icon-menu',
    children: [
      {
        name: 'Accion',
        url: '/accion',
        icon: 'icon-settings'
      },
      {
        name: 'Maestro',
        url: '/maestro',
        icon: 'icon-settings'
      },
      {
        name: 'Programación Citas y Potenciales',
        url: '/programacion',
        icon: 'icon-settings'
      },
    ]
  },
  {
    name: 'Tablero',
    url: '/tablero',
    icon: 'icon-cursor'
  },
  {
    name: 'Llamada',
    url: '/llamada',
    icon: 'icon-cursor'
  },
  {
    name: 'Potencial',
    url: '/seguimiento',
    icon: 'icon-cursor'
  },
  {
    name: 'Cita',
    url: '/cita',
    icon: 'icon-cursor'
  },
  {
    name: 'Proceso',
    url: '/proceso',
    icon: 'icon-cursor'
  },
  {
    name: 'Reporte',
    url: '/reporte',
    icon: 'icon-cursor'
  },

];
