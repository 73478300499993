<div class="app-body" style="background: #E9EEC1 !important;">
  <main class="main d-flex align-items-center">
    <div class="container">
      <div class="row">
        <div class="col-md-8 mx-auto text-center">
          <img [src]="urlImagen + 'eficiencianetytirados.png'" class="img-fluid mb-5" width="300">
          <div class="card-group" style="box-shadow:
          inset 0 -3em 3em rgba(0,0,0,0.1),
                0 0  0 2px rgb(255,255,255),
                0.3em 0.3em 1em rgba(0,0,0,0.3);">
            <div class="card p-4">
              <div class="card-body">
                <h1>Login</h1>
                <p class="text-muted">Iniciar sesión en su cuenta</p>
                <div class="row">
                  <!-- <div class="col-sm-12 col-lg-4 my-1">
                    <button type="button" class="btn btn-block btn-light px-2" (click)="isTercero = true"
                      *ngIf="!isAdmin && !isEmpleado && !isTercero">Cliente</button>
                  </div> -->
                  <div class="col-sm-12 col-lg-6 my-1">
                    <button type="button" class="btn btn-block btn-light px-2" (click)="isEmpleado = true"
                      *ngIf="!isAdmin && !isEmpleado && !isTercero">Agente / Empleado / Abogado</button>
                  </div>
                  <div class="col-sm-12 col-lg-6 my-1">
                    <button type="button" class="btn btn-block btn-light px-2" (click)="isAdmin = true"
                      *ngIf="!isAdmin && !isEmpleado && !isTercero">Administrador</button>
                  </div>
                </div>
                <form [formGroup]="form" (ngSubmit)="login($event)" *ngIf="isAdmin || isEmpleado || isTercero">
                  <div class="input-group mb-3" *ngIf="isCargando">
                    <span class="cargando mx-auto">
                      <span class="letra-animada">C</span>
                      <span class="letra-animada">a</span>
                      <span class="letra-animada">r</span>
                      <span class="letra-animada">g</span>
                      <span class="letra-animada">a</span>
                      <span class="letra-animada">n</span>
                      <span class="letra-animada">d</span>
                      <span class="letra-animada">o</span>
                    </span>
                  </div>
                  <div class="input-group mb-3" *ngIf="isAdmin || isEmpleado">
                    <div class="input-group-prepend">
                      <span class="input-group-text"><i class="icon-user"></i></span>
                    </div>
                    <input formControlName="email" type="text" class="form-control" placeholder="Correo electrónico"
                      autocomplete="username" [required]="isAdmin || isEmpleado">
                  </div>
                  <div class="input-group mb-3" *ngIf="isTercero">
                    <div class="input-group-prepend">
                      <span class="input-group-text"><i class="icon-user"></i></span>
                    </div>
                    <input formControlName="nit" type="text" class="form-control" placeholder="Documento de identidad"
                      autocomplete="id" [required]="isTercero">
                  </div>
                  <div class="input-group mb-4" *ngIf="isAdmin || isEmpleado">
                    <div class="input-group-prepend">
                      <span class="input-group-text"><i class="icon-lock"></i></span>
                    </div>
                    <input formControlName="password" type="password" class="form-control" placeholder="Password"
                      autocomplete="current-password" [required]="isAdmin || isEmpleado">
                  </div>
                  <div class="row">
                    <div class="col-12">


                      <!-- <p>myForm value: {{ form.value | json }}</p>
                      <p>myForm status: {{ form.status | json }}</p> -->


                      <button [disabled]="form.invalid" type="submit"
                        class="btn btn-block btn-primary px-4">Ingresar</button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </main>
</div>