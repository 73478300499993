import { INavData } from '@coreui/angular';

export const navItemsEmpleado: INavData[] = [
  {
    name: 'Tablero de Servicios',
    url: '/dashboard',
    icon: 'icon-speedometer',
  },
  {
    title: true,
    name: 'Módulos'
  },
  {
    name: 'Tablero',
    url: '/tablero',
    icon: 'icon-cursor'
  },
  {
    name: 'Llamada',
    url: '/llamada',
    icon: 'icon-cursor'
  },
  {
    name: 'Potencial',
    url: '/seguimiento',
    icon: 'icon-cursor'
  },
  {
    name: 'Cita',
    url: '/cita',
    icon: 'icon-cursor'
  },
  {
    name: 'Proceso',
    url: '/proceso',
    icon: 'icon-cursor'
  },

];
