import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Params } from '@angular/router';
import Swal from 'sweetalert2';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
// import { Router } from '@angular/router';

import { AuthService } from './../../core/servicios/auth.service';
import { Admin } from './../../core/interfaces/admin';
import { Empleado } from './../../core/interfaces/empleado';
import { Tercero } from './../../core/interfaces/tercero';
import { environment } from './../../../environments/environment';
import { QueryService } from './../../core/servicios/-query.service';

@Component({
  selector: 'app-dashboard',
  templateUrl: 'login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {

  form: FormGroup;
  public urlImagen: string = environment.urlImagen;
  public isAdmin = false;
  public isEmpleado = false;
  public isTercero = false;
  public isCargando = false;
  public enlace: string;

  constructor(
    private route: ActivatedRoute,
    private formBuilder: FormBuilder,
    private authService: AuthService,
    private queryService: QueryService,
  ) {
    this.buildForm();
  }

  ngOnInit(): void {
    this.route.params.subscribe((params: Params) => {
      this.enlace = params.enlace;

      if (this.enlace === 'tercero') {
        this.isTercero = true;
      } else if (this.enlace === 'empleado') {
        this.isEmpleado = true;
      } else if (this.enlace === 'admin') {
        this.isAdmin = true;
      }
    });
  }


  login(event: Event) {
    event.preventDefault();
    this.isCargando = true;

    if (this.form.valid) {

      this.form.get('email').disable();
      this.form.get('nit').disable();
      this.form.get('password').disable();
      const value = this.form.value;

      this.queryService.query_1001('' + value.email, '' + value.password).subscribe(
        (admin: Admin) => {
          if (admin.id) {
            Swal.fire({
              icon: 'success',
              html: 'La verificación de tu identidad ha sido exitosa ¡Bienvenido!',
              showConfirmButton: false,
              timer: 3000
            });

            this.authService.crearAdmin(admin);
          } else {
            this.loginEmpledo(event);
          }
        },
        error => {
          this.loginEmpledo(event);
        },
        () => {
        }
      );
    } else {
      Swal.fire({
        icon: 'error',
        html: 'ERROR, completa la información para continuar',
        showConfirmButton: false,
        timer: 3000
      });
      this.isCargando = false;

      this.form.get('email').enable();
      this.form.get('nit').enable();
      this.form.get('password').enable();
    }
  }

  loginEmpledo(event: Event): void {
    event.preventDefault();
    // if (this.form.valid) {
    const value = this.form.value;

    this.queryService.query_1004('' + value.email, '' + value.password).subscribe(
      (empleado: Empleado) => {
        if (empleado.id) {
          Swal.fire({
            icon: 'success',
            html: 'La verificación de tu identidad ha sido exitosa ¡Bienvenido!',
            showConfirmButton: false,
            timer: 3000
          });

          this.authService.crearEmpleado(empleado);
        } else {
          this.loginTercero(event);
        }
      },
      error => {
        this.loginTercero(event);
      },
      () => {
      }
    );
    // }
  }

  loginTercero(event: Event): void {
    event.preventDefault();
    // if (this.form.valid) {
    const value = this.form.value;

    this.queryService.query_1006('' + value.nit).subscribe(
      (tercero: Tercero) => {
        if (tercero.cedula) {
          Swal.fire({
            icon: 'success',
            html: 'La verificación de tu identidad ha sido exitosa ¡Bienvenido!',
            showConfirmButton: false,
            timer: 3000
          });

          this.authService.crearTercero(tercero, this.enlace);
        } else {
          Swal.fire({
            icon: 'error',
            html: 'ERROR, no pudimos validar tu usuario y contraseña',
            showConfirmButton: false,
            timer: 3000
          });

          this.isCargando = false;
        }
      },
      error => {
        Swal.fire({
          icon: 'error',
          html: 'ERROR, no pudimos validar tu usuario y contraseña',
          showConfirmButton: false,
          timer: 3000
        });
        this.isCargando = false;

        this.form.get('email').enable();
        this.form.get('nit').enable();
        this.form.get('password').enable();
      },
      () => {
      }
    );
    // }
  }

  private buildForm() {
    this.form = this.formBuilder.group({
      email: [null],
      nit: [null],
      password: [null],
    });
  }


}
