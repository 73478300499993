import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from './../../../environments/environment';
import { Admin } from './../../core/interfaces/admin';
import { Accion } from './../../core/interfaces/accion';
import { Empleado } from './../../core/interfaces/empleado';
import { Tercero } from './../../core/interfaces/tercero';
import { Programacioncita } from './../../core/interfaces/programacioncita';


@Injectable({
  providedIn: 'root'
})
export class QueryService {

  // private headers;

  constructor(
    private http: HttpClient,
    // private localstorageService: LocalStorageService,
  ) {
    // try {
    //   this.headers = new HttpHeaders({
    //     'Content-Type': 'application/json',
    //     'Accept': 'application/json, text/plain, */*',
    //     'Accept-Encoding': 'gzip, deflate, br',
    //     'Accept-Language': 'es-ES,es;q=0.9',
    //     'Connection': 'keep-alive',
    //     // 'Access-Control-Allow-Headers': 'X-Requested-With, Content-Type, Accept, Origin, Authorization, HTTP_JWT, tipo, fk',
    //     'JWT': this.localstorageService.get('QYfRuxeadphBcSuOAczF').toString(),
    //     'tipo': this.localstorageService.get('qFUNseVbOFIMuWyQSdxC').toString(),
    //     'fk': this.localstorageService.get('eVBEvatmKiVlokbfjfwJ').toString(),
    //     'correoelectronico': this.localstorageService.get('CBIfXgisPiSqolrJrNFd').toString(),
    //   });
    // } catch (error) {
    // }

  }

  public query_1000(value: any): Observable<any[]> { // Maestro
    return this.http.post<any[]>(environment.urlApi + '/Query/1000', { campo: value });
  }

  public query_1001(value1: any, value2: any): Observable<Admin> { // Loguin de Admin
    return this.http.post<Admin>(environment.urlApi + '/Query/1001', { usuario: value1, contrasena: value2 });
  }

  public query_1002(value1: any, value2: any): Observable<object> { // Crear SessionAdmin
    return this.http.post(environment.urlApi + '/Query/1002', { fkadmin: value1, usuario: value2 });
  }

  public query_1003(value1: any, value2: any, value3: any, value4: any): Observable<Accion> { // Permisos en Accion segun perfil
    return this.http.post<Accion>(environment.urlApi + '/Query/1003', { modulo: value1, fk: value2, accion: value3, perfil: value4 });
  }

  public query_1004(value1: any, value2: any): Observable<Empleado> { // Loguin de Empleado
    return this.http.post<Empleado>(environment.urlApi + '/Query/1004', { correoelectronico: value1, contrasena: value2 });
  }

  public query_1005(value1: any, value2: any, value3: any): Observable<object> { // Crear SessionEmpleado
    return this.http.post(environment.urlApi + '/Query/1005', { fkempleado: value1, correoelectronico: value2, departamento: value3 });
  }

  public query_1006(value1: any): Observable<Tercero> { // Loguin de Tercero
    return this.http.post<Tercero>(environment.urlApi + '/Query/1006', { nit: value1 });
  }

  public query_1007(value1: any, value2: any): Observable<object> { // Crear SessionTercero
    return this.http.post(environment.urlApi + '/Query/1007', { fktercero: value1, correoelectronico: value2 });
  }

  /***************************************************************************************************************************** */

  public query_1010(value1: any, value2: any, value3: any): Observable<Programacioncita[]> { // Citas Disponibles
    return this.http.post<Programacioncita[]>(environment.urlApi + '/Query/1010', { fkempleado: value1, tipo: value2, dia: value3 });
  }

  public query_1011(): Observable<any[]> { // Maestro
    return this.http.post<any[]>(environment.urlApi + '/Query/1011', { value: null });
  }

  public query_1012(value: any): Observable<any[]> { // Maestro con Valor
    return this.http.post<any[]>(environment.urlApi + '/Query/1012', { tipo: value });
  }

  public query_1014(value: any): Observable<any[]> { // Traer datos Empleado
    return this.http.post<any[]>(environment.urlApi + '/Query/1014', { fk: value });
  }

  public query_1015(value: any): Observable<any[]> { // Traer datos Tercero / SessionTercero
    return this.http.post<any[]>(environment.urlApi + '/Query/1015', { fktercero: value });
  }
  public query_1016(value: any): Observable<any[]> { // Traer datos Tercero / Seguimiento
    return this.http.post<any[]>(environment.urlApi + '/Query/1016', { fktercero: value });
  }
  public query_1017(value: any): Observable<any[]> { // Traer datos Tercero / Cita
    return this.http.post<any[]>(environment.urlApi + '/Query/1017', { fktercero: value });
  }
  public query_1018(value: any): Observable<any[]> { // Traer datos Tercero / Proceso
    return this.http.post<any[]>(environment.urlApi + '/Query/1018', { fktercero: value });
  }
  public query_1019(): Observable<any[]> { // Cargar llamadas
    return this.http.post<any[]>(environment.urlApi + '/Query/1019', { value: null });
  }

  public query_1021(value: any): Observable<any[]> {
    return this.http.post<any[]>(environment.urlApi + '/Query/1021', { id: value });
  }

  public query_1022(value1: any, value2: any): Observable<any[]> {
    return this.http.post<any[]>(environment.urlApi + '/Query/1022', { fecha_inicio: value1, fecha_fin: value2 });
  }
  
  public query_1023(value1: any): Observable<any[]> {
    return this.http.post<any[]>(environment.urlApi + '/Query/1023', { fkempleado: value1 });
  }
  
  public query_1024(value1: any): Observable<any[]> {
    return this.http.post<any[]>(environment.urlApi + '/Query/1024', { cedula: value1 });
  }

  public query_1025(value1: any, value2: any, value3: any, value4: any): Observable<any[]> {
    return this.http.post<any[]>(environment.urlApi + '/Query/1025', { dia: value1, tipo: value2, departamento: value3, resultado: value4 });
  }

  public query_1026(value1: any, value2: any): Observable<any[]> {
    return this.http.post<any[]>(environment.urlApi + '/Query/1026', { dia: value1, resultado: value2 });
  } 

  public query_1027(): Observable<any[]> {
    return this.http.post<any[]>(environment.urlApi + '/Query/1027', { value: null });
  }

  /******************************************************************* */
  public query_2001(value1: any, value2: any): Observable<any[]> {
    return this.http.post<any[]>(environment.urlApi + '/Query/2001', { id: value1, documento: value2 });
  }

  /******************************************************************* */
  public query_3001(value1: any): Observable<any[]> {
    return this.http.post<any[]>(environment.urlApi + '/Query/3001', { value1: value1 });
  }

  public query_3002(value1: any, value2: any): Observable<any[]> {
    return this.http.post<any[]>(environment.urlApi + '/Query/3002', { value1: value1, value2: value2 });
  }

  public query_3003(value1: any, value2: any): Observable<any[]> {
    return this.http.post<any[]>(environment.urlApi + '/Query/3003', { value1: value1, value2: value2 });
  }

  public query_3004(value1: any, value2: any, value3: any): Observable<any[]> {
    return this.http.post<any[]>(environment.urlApi + '/Query/3004', { value1: value1, value2: value2, value3: value3 });
  }

  public query_3005(value1: any, value2: any): Observable<any[]> {
    return this.http.post<any[]>(environment.urlApi + '/Query/3005', { value1: value1, value2: value2 });
  }

  public query_3006(value1: any, value2: any, value3: any): Observable<any[]> {
    return this.http.post<any[]>(environment.urlApi + '/Query/3006', { value1: value1, value2: value2, value3: value3});
  }

  public query_4001(value1: any, value2: any): Observable<any[]> {
    return this.http.post<any[]>(environment.urlApi + '/Query/4001', { value1: value1, value2: value2});
  }

  public query_4002(value1: any, value2: any): Observable<any[]> {
    return this.http.post<any[]>(environment.urlApi + '/Query/4002', { value1: value1, value2: value2});
  }

  public query_4003(value1: any, value2: any): Observable<any[]> {
    return this.http.post<any[]>(environment.urlApi + '/Query/4003', { value1: value1, value2: value2});
  }

  public query_4004(value1: any, value2: any): Observable<any[]> {
    return this.http.post<any[]>(environment.urlApi + '/Query/4004', { value1: value1, value2: value2});
  }

  public query_4005(value1: any, value2: any): Observable<any[]> {
    return this.http.post<any[]>(environment.urlApi + '/Query/4005', { value1: value1, value2: value2});
  }


}
