import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from './../../../environments/environment';
import { Sessionadmin } from './../interfaces/sessionadmin';
import { Observable, BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SessionadminService {

  public arrayObservable = new BehaviorSubject<Sessionadmin[]>(null);
  public array$ = this.arrayObservable.asObservable();

  constructor(
    private http: HttpClient
  ) { }

  // public getAll(): Observable<Sessionadmin[]> {/**GetAll */
  //   return this.http.get<Sessionadmin[]>(environment.urlApi + '/sessionadmin');
  // }

  // public post(fkadmin: number, usuario: string): Observable<object> {/**Create */
  //   return this.http.post(environment.urlApi + '/sessionadmin', { fkadmin: fkadmin, usuario: usuario });
  // }

  // public get(id: number): Observable<Sessionadmin> {/**GetOne */
  //   return this.http.get<Sessionadmin>(environment.urlApi + '/sessionadmin/' + id);
  // }

  // public put(object: Sessionadmin): Observable<object> {/**Update */
  //   return this.http.put(environment.urlApi + '/sessionadmin/' + object.id, object);
  // }

  public delete(object: Sessionadmin): Observable<object> {/**Delete */
    try {
      return this.http.delete(environment.urlApi + '/sessionadmin/' + object.id);
    } catch(error) {
      return null;
    }
  }
}
