import { AuthService } from './../../core/servicios/auth.service';
import { BnNgIdleService } from 'bn-ng-idle';
import { Component, OnInit } from '@angular/core';
import { Empleado } from './../../core/interfaces/empleado';
import { environment } from './../../../environments/environment';
import { navItemsAdmin } from '../../_nav_admin';
import { navItemsEmpleado } from '../../_nav_empleado';
import { navItemsTercero } from '../../_nav_tercero';
import { QueryService } from './../../core/servicios/-query.service';
import { Sessionadmin } from './../../core/interfaces/sessionadmin';
import { Sessionempleado } from './../../core/interfaces/sessionempleado';
import { Sessiontercero } from './../../core/interfaces/sessiontercero';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-dashboard',
  templateUrl: './default-layout.component.html',
  styleUrls: ['./default-layout.component.css']
})
export class DefaultLayoutComponent implements OnInit {
  public sidebarMinimized = false;
  public navItems;
  public urlImagen: string = environment.urlImagen;
  public perfil: string;
  public nombre: string;
  public departamento: string;
  public sessiontercero: Sessiontercero;
  public sessionadmin: Sessionadmin;
  public sessionempleado: Sessionempleado;
  public empleado: Empleado;
  public cargando: boolean = false;

  toggleMinimize(e) {
    this.sidebarMinimized = e;
  }

  constructor(
    private authService: AuthService,
    private bnIdle: BnNgIdleService,
    // private notificacionService: NotificacionService,
    private queryService: QueryService,
  ) { }

  ngOnInit(): void {
    this.perfil = this.authService.validar();
    if (this.perfil === 'Tercero') {
      this.navItems = navItemsTercero;
      this.sessiontercero = this.authService.obtenerTercero();
      this.nombre = this.sessiontercero.correoelectronico;
    } else if (this.perfil === 'Admin') {
      this.navItems = navItemsAdmin;
      this.sessionadmin = this.authService.obtenerAdmin();
      this.nombre = this.sessionadmin.usuario;
    } else if (this.perfil === 'Empleado') {
      this.navItems = navItemsEmpleado;
      this.sessionempleado = this.authService.obtenerEmpleado();
      this.nombre = this.sessionempleado.correoelectronico;
      this.departamento = this.sessionempleado.departamento;
    }

    this.bnIdle.startWatching(6000).subscribe((isTimedOut: boolean) => {
      if (isTimedOut) {
        this.authService.salir();
      }
    });
  }

  cargarLlamadas():void {
    this.cargando = true;
    this.queryService.query_1019().subscribe(
      response => {
        // Manejar la respuesta exitosa aquí
        Swal.fire({
          icon: 'success',
          html: 'Se cargaron las llamadas.',
          showConfirmButton: false,
          timer: 3000
        });
        this.cargando = false;
      },
      error => {
        // Manejar el error aquí
        console.error('Error en la solicitud:', error);
        Swal.fire({
          icon: 'success',
          html: 'Se cargaron las llamadas.',
          showConfirmButton: false,
          timer: 3000
        });
        this.cargando = false;
      }
    );
    
  }

  public logout() {
    this.authService.salir();
  }
}
